<template>
  <FormWrapper :is-loading="isLoading">
    <PageTitle
      :title="title"
      slot="title"
      link="/helpContent/openPositions"
    />
    <ValidationObserver ref="validator">
      <FormRow>
        <div class="col-sm-1"></div>
        <div class="col-sm-3 formDiv">
          <label class="formLable">Designation</label>
        </div>
         <div class="col-sm-1"></div>
        <div class="col-sm-3">
          <inputText  v-model="form.designation"   rules="required" :readonly="readOnly" />
        </div>
      </FormRow>
      <FormRow>
        <div class="col-sm-1"></div>
        <div class="col-sm-3 formDiv">
          <label class="formLable">Department</label>
        </div>
         <div class="col-sm-1"></div>
        <div class="col-sm-3">
          <inputText  v-model="form.department"   rules="required" :readonly="readOnly"/>
        </div>
      </FormRow>
      <FormRow>
        <div class="col-sm-1"></div>
        <div class="col-sm-3 formDiv">
          <label class="formLable">Company</label>
        </div>
         <div class="col-sm-1"></div>
        <div class="col-sm-3">
          <inputText  v-model="form.company"   rules="required" :readonly="readOnly" />
        </div>
      </FormRow>
      <FormRow>
        <div class="col-sm-1"></div>
        <div class="col-sm-3 formDiv">
          <label class="formLable">Reporting To	</label>
        </div>
         <div class="col-sm-1"></div>
        <div class="col-sm-3">
          <inputText  v-model="form.reportingTo"    rules="required"  :readonly="readOnly" />
        </div>
      </FormRow>
      <FormRow>
        <div class="col-sm-1"></div>
        <div class="col-sm-3 formDiv">
          <label class="formLable">Date of Joining</label>
        </div>
         <div class="col-sm-1"></div>
        <div class="col-sm-3">
          <inputDate  v-model="form.dateOfJoining"    :readonly="readOnly"  rules="required"/>
        </div>
      </FormRow>
      
      <FormRow>
        <div class="col-sm-1"></div>
        <div class="col-sm-3 formDiv">
          <label class="formLable">Salary Range</label>
        </div>
         <div class="col-sm-1"></div>
        <div class="col-sm-3">
          <inputText  v-model="form.salaryRange"   rules="required" :readonly="readOnly" />
        </div>
      </FormRow>
      <FormRow v-for="(item, index) in editorList" :key="index">
        <div class="col-sm-1"></div>
        <div class="col-sm-4 formDiv">
          <label class="formLable">{{item.HeadingName}}</label>
        </div>
         <!-- <div class="col-sm-1" style="padding: 0px 0px 0px 0px;">
          <ButtonGroup>
            <FormButton type="success" @click="add(item)">Add</FormButton>
          </ButtonGroup>
         </div> -->
       
          <div class="col-sm-6"  @click="add(item)" v-html="item.Value" style="overflow-y: scroll;max-height: 220px;min-height:200px; border: 2px solid #81c3ec;"></div>
        <!-- <div class="col-sm-3 formDiv">
          <label class="formLable">{{item.HeadingName}}</label>
        </div>
        <div class="col-sm-2 formDiv">
          <ButtonGroup>
            <FormButton type="success" @click="add(item)">Add</FormButton>
          </ButtonGroup>
        </div>
        <div class="col-sm-7" v-html="item.Value" style="overflow: scroll;max-height: 220px;min-height:100px;"></div> -->
      </FormRow>
      <!-- <FormRow>
        <div class="col-sm-1"></div>
        <div class="col-sm-3 formDiv">
          <label class="formLable">Position Concept</label>
        </div>
         <div class="col-sm-1"></div>
        <div class="col-sm-6">
          <InputTextArea :maxlength="2000" v-model="form.positionConcept"   rules="required" :readonly="readOnly" />
        </div>
      </FormRow>
      <FormRow>
        <div class="col-sm-1"></div>
        <div class="col-sm-3 formDiv">
          <label class="formLable">Experience Required</label>
        </div>
         <div class="col-sm-1"></div>
        <div class="col-sm-6">
          <InputTextArea :maxlength="2000" v-model="form.experienceRequired"   rules="required" :readonly="readOnly" />
        </div>
      </FormRow>

      <FormRow>
        <div class="col-sm-1"></div>
        <div class="col-sm-3 formDiv">
          <label class="formLable"> Educational Qualifications</label>
        </div>
         <div class="col-sm-1"></div>
        <div class="col-sm-6">
          <InputTextArea :maxlength="2000"  v-model="form.educationalQualifications"   rules="required" :readonly="readOnly" />
        </div>
      </FormRow>

      <FormRow>
        <div class="col-sm-1"></div>
        <div class="col-sm-3 formDiv">
          <label class="formLable">Skill Required</label>
        </div>
         <div class="col-sm-1"></div>
        <div class="col-sm-6">
          <InputTextArea :maxlength="2000"  v-model="form.skillRequired"   rules="required" :readonly="readOnly" />
        </div>
      </FormRow>
    <FormRow>
      <div class="col-sm-1"></div>
        <div class="col-sm-3 formDiv">
          <label class="formLable">Job Responsibilities</label>
        </div>
         <div class="col-sm-1"></div>
        <div class="col-sm-6">
          <InputTextArea :maxlength="2000" v-model="form.jobResponsibilities"   rules="required" :readonly="readOnly" />
        </div>
    </FormRow>
    <FormRow>
        <div class="col-sm-1"></div>
        <div class="col-sm-3 formDiv">
          <label class="formLable">Questions for Screening</label>
        </div>
         <div class="col-sm-1"></div>
        <div class="col-sm-6">
          <InputTextArea :maxlength="2000" v-model="form.questionsforScreening"   rules="required" :readonly="readOnly" />
        </div>
      </FormRow> 
     -->
     <FormRow>
        <div class="col-sm-1"></div>
        <div class="col-sm-3 formDiv">
          <input type="checkbox" id="isActive" name="isActive"  v-model="form.isActive"  @click="terms" :disabled="readOnly">
          <label class="formLable" for="isActive">  Is Active</label>
          <div class="error" v-if="isActive">
              Required.
          </div>
        </div>
      
      </FormRow> 
    </ValidationObserver>
    <br />
    <FormRow>
      <div class="col-sm-6">
        <ButtonGroup>
          <FormButton
            type="info"
            @click="
              () => {
                $router.go(-1);
              }
            "
            >Back</FormButton
          >
          <FormButton type="success" @click="sendData()" :disabled="readOnly">Save</FormButton>
        </ButtonGroup>
      </div>
    </FormRow>
    <Popup
      title="Success"
      type="success"
      :value="showMessage.isVisible"
      @close="() => (showMessage.isVisible = false)"
      :closable="true"
    >
      {{ showMessage.message }}
      <div slot="footer">
        <FormButton
          @click="
            () => {
              $router.go(-1);
            }
          "
          >Ok</FormButton
        >
      </div>
    </Popup>
    <Popup
      title="Information"
      type="info"
      :value="infoMessage.isVisible"
      @close="() => (infoMessage.isVisible = false)"
      :closable="true"
    >
      {{ infoMessage.message }}
    </Popup>
    <Popup width="50"
      :title="editorTitle"
      type="info"
      :value="editor.isVisible"
      @close="() => (editor.isVisible = false)"
      :closable="true"
    >
    <div v-for="(item,index) in editorList" :key="index">
      <EditorComponent v-if="editorId==item.Id"
        v-model="item.Value"
      />
    </div>
      <div slot="footer">
        <FormButton
          @click="
          () => (editor.isVisible = false) "
          >Save</FormButton
        >
      </div>
    </Popup>
  </FormWrapper>
</template>

<script>
import FormWrapper from "Components/form/FormWrapper";
import PageTitle from "Components/layout/PageTitle";
import loadingMixin from "Mixins/loadingMixin";
import { addUpdatePosition, getPositionsList} from "../api";
import InputDate from "Components/form/InputDate";
// import InputTel from "Components/form/InputTel";
import { ValidationObserver } from "vee-validate";
import validateSelectedRows from "Mixins/validateSelectedRows";
export default {
  name: "add",
  mixins: [loadingMixin,validateSelectedRows],
  components: {
    FormWrapper,
    PageTitle,
    ValidationObserver,
    InputDate,
    // InputTel,
  },
  data() {
    return {
      title:'Add Position',
      editor: {
        isVisible: false,
      },
      showMessage: {
        isVisible: false,
      },
      infoMessage: {
        isVisible: false,
      },
      filtering: {
        year: new Date().getFullYear(),
      },
      sorting: {
        key: "id",
        order: "asc",
      },
      pagination: {
        perPage: 10,
        page: 1,
        total: 0,
      },
      form: {
    id:0,				
    designation:'',					
    department:'',					
    company:'',					
    reportingTo:'',					
    dateOfJoining:'',								
    salaryRange:'',				
    positionConcept:'',		
    experienceRequired:'',				
    educationalQualifications:'',					
    skillRequired:'',				
    jobResponsibilities:'',				
    questionsforScreening:'',	
    isActive:false,			
      },
      isActive:false,	
            readOnly:false,
            editorId:0,
            editorTitle:'Editor',
            editorList:[{
                "Id": 1,
                "HeadingName": "Position Concept",
                "HeadingKey": "positionConcept",
                "Value": ""
            },
            {
                "Id": 2,
                "HeadingName": "Experience Required",
                "HeadingKey": "experienceRequired",
                "Value": ""
            },
            {
                "Id": 3,
                "HeadingName": "Educational Qualifications",
                "HeadingKey": "educationalQualifications",
                "Value": ""
            },
            {
                "Id": 4,
                "HeadingName": "Skill Required",
                "HeadingKey": "skillRequired",
                "Value": ""
            },
            {
                "Id": 5,
                "HeadingName": "Job Responsibilities",
                "HeadingKey": "jobResponsibilities",
                "Value": ""
            },
            {
                "Id": 6,
                "HeadingName": "Questions for Screening",
                "HeadingKey": "questionsforScreening",
                "Value": ""
            }],
    };
    
  },
  // computed: {
  //   checkedNames () {
  //      return this.names.filter(item => item.checked).map(name => name.name)
  //   }
  // },
  created() {
    if (this.$route.params.id) {
      this.showLoader();
      this.title='Edit Position'
      this.form.id = atob(this.$route.params.id);
      this.getData();
    }
  },
  methods: {
    terms(e){
      const {checked,defaultValue } = e.target;

if(checked){
  this.form.isActive=true;
}else{
this.form.isActive=false;
}
    },
    add(e) {
      this.editorTitle=e.HeadingName;
      this.editorId=e.Id;
      this.editor.isVisible=true;

    },
    getData() {
      const { key, order } = this.sorting;
        const { perPage, page } = this.pagination;

        const filters = {};
        filters.id = this.form.id;
        const data = {
          filterjson: {
            filter: [filters],
            sort: [{ key, order }],
            paging: [
              {
                startIndex: page,
                pagesize: perPage,
              },
            ],
          },
        };
      getPositionsList(data).then((res) => {
        this.form.designation=res.data[0].Designation;			
	this.form.department=res.data[0].Department				
	this.form.company=res.data[0].Company				
	this.form.reportingTo=res.data[0].ReportingTo			
	this.form.dateOfJoining=res.data[0].DateOfJoining			
	this.form.salaryRange=res.data[0].SalaryRange			
	// this.form.positionConcept=res.data[0].PositionConsept		
	// this.form.experienceRequired=res.data[0].ExperienceRequired		
	// this.form.educationalQualifications=res.data[0].EducationalQualifications	
	// this.form.skillRequired=res.data[0].SkillRequired			
	// this.form.jobResponsibilities=res.data[0].JobResponsibilities	
	// this.form.questionsforScreening=res.data[0].QuestionsforScreening	
	this.form.isActive=parseInt(res.data[0].isactive)?true:false; 	
  this.editorList.forEach((item)=>{
    if (item.Id==1) {
      item.Value=res.data[0].PositionConsept;
    }
    if (item.Id==2) {
      item.Value=res.data[0].ExperienceRequired;
    }
    if (item.Id==3) {
      item.Value=res.data[0].EducationalQualifications;
    }
    if (item.Id==4) {
      item.Value=res.data[0].SkillRequired;
    }

    if (item.Id==5) {
      item.Value=res.data[0].JobResponsibilities;
    }
    if (item.Id==6) {
      item.Value=res.data[0].QuestionsforScreening;
    }
  })	
      });
      this.hideLoader();
    },
    // send data to server
    sendData() {
      // 
      this.$refs.validator.validate().then((result) => {
        if (result ) {
          this.showMessage.isVisible = false;
          this.infoMessage.isVisible = false;
          const data = {
                id: this.form.id == 0 ? 0 : parseInt(this.form.id),
                designation:this.form.designation	,				
                department:this.form.department	,				
                company:this.form.company	,				
                reportingTo:this.form.reportingTo	,				
                dateOfJoining:this.form.dateOfJoining	,		
                salaryRange:this.form.salaryRange	,					
                // positionConcept:this.form.positionConcept	,				
                // experienceRequired:this.form.experienceRequired	,			
                // educationalQualifications:this.form.educationalQualifications	,			
                // skillRequired:this.form.skillRequired	,			
                // jobResponsibilities:this.form.jobResponsibilities,	
                // questionsforScreening:this.form.questionsforScreening,
                positionConcept:this.editorList[0].Value,				
                experienceRequired:this.editorList[1].Value,			
                educationalQualifications:this.editorList[2].Value	,			
                skillRequired:this.editorList[3].Value	,			
                jobResponsibilities:this.editorList[4].Value,	
                questionsforScreening:this.editorList[5].Value,
                isActive:this.form.isActive?1:0,			
          };
          addUpdatePosition(data).then((res) => {
            if (res.data[0].status == 1) {
              this.form = {};
              this.showMessage.isVisible = true;
              this.showMessage.message = res.data[0].message;
            } else {
              this.infoMessage.isVisible = true;
              this.infoMessage.message = res.data[0].message;
            }
          });
        }

      });
    },
 
  },
};
</script>
<style lang="scss">
  .error {
    color: #ff3547;
    position: absolute;
    left: 0;
    font-size: 11px;
    line-height: 13px;
    top: calc(100% + 2px);
    font-weight: 400;
  }
.formDiv{
  padding-top: 10px;
}
.formLable{
  color: #515a6e;
  font-size: 15px;
  
}
.checkboxLable{
  color: #515a6e;
  font-size: 14px;
  margin-left: 10px;
}
.checkbox {
        position: relative;
        padding-top: 20px;
    }
    .label {
        display: flex;
    }
    .displayImageFile{
        height: 150px;
        width: 150px;
    }
    .displaPDF{
        height: 150px;
        width: 150px;
    }
    textarea{
        height: 200px !important;
    }
</style>
